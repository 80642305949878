<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-title">三阅官网后台管理系统</div>
            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <el-form-item prop="username">
                    <el-input v-model="param.userName" placeholder="请输入用户名">
                        <template #prepend>
                            <el-button icon="el-icon-user"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input
                        type="password"
                        placeholder="请输入密码"
                        v-model="param.passWord"
                        @keyup.enter="login()">
                        <template #prepend>
                            <el-button icon="el-icon-lock"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button type="primary" @click="login()">登录</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            param: {
              userName: "",
              passWord: "",
            },
            rules: {
              userName: [
                    { required: true, message: "请输入用户名", trigger: "blur" }
                ],
              passWord: [
                    { required: true, message: "请输入密码", trigger: "blur" }
                ]
            }
        };
    },
    created() {
        this.$store.commit("clearTags");
    },
    methods: {
      //登录
      login:function () {
        this.$axios({
          url:this.localpath+'managers/login',
          method:'post',
          //发送格式为json
          data:JSON.stringify(this.param),
          headers:
              {
                'Content-Type': 'application/json',
                'dataType':'json'
              }
        }).then((res)=>{
          if (res.data.code===0){
            localStorage.setItem('token','Bearer '+res.data.msg)
            localStorage.setItem('tokentime',new Date().getTime())

            this.$router.push('/dashboard')
          }else{
            this.$message({
              message: '用户名或密码错误,请重新输入',
              type: 'error',

            })
          }
        })
      }
    }
};
</script>
<style scoped>
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/img/home-bg.jpg);
    background-size: 100%;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    border-bottom: 1px solid #ddd;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 400px;
    margin: -190px 0 0 -195px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
    overflow: hidden;
}
.ms-content {
    padding: 30px 30px;
}
.login-btn {
    text-align: center;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
</style>
